import image2 from "../assets/konkursi.png"
import aplikacioni from "../assets/deklarata.pdf"
import aplikacioniPunesimit from "../assets/aplikacioniPerPunesim.pdf"
import mirembajtjeHigjiene from "../assets/mirembajtesHigjiene.pdf"
import kapjeQenve from "../assets/Konkursi punëtor fizik për kapjen e qenëve endacak 14.05.2024.pdf"
import vozites from "../assets/07.03.2024 Vozitës shqip.pdf"
import vozitesSrb from "../assets/07.03.2024 Vozitës srb.pdf"
import driver from "../assets/driver.jpg"
import dog from "../assets/kapjeqen.jpg"
import zyrtareLigjore from "../assets/07.03.2024 Zyrtar i Lartë Ligjore shqi.pdf"
import law from "../assets/law.jpg"
import zyrtareLigjoreSrb from "../assets/07.03.2024 Zyrtar i Larte Ligjor srb.pdf"
import teknikveterinar from "../assets/konkursi Teknik Veterinar.pdf"
import veterinarian from "../assets/veterinarian.jpg"
import greenAttacks from "../assets/greenAttack.jpeg"
import mirembajtjeSiperfaqeveGjelbra from "../assets/Konkurs - Punetor fizik ne ngritjen sipërfaqeve gjelbruese 10.05.2024.pdf"
import forest from "../assets/forest.jpg"
import germiaPark from "../assets/Germia_1.jpg"
import pylltar from "../assets/Konkurs - Roje Pylli Pylltar 10.05.2024.pdf"
import peisazhiGermise from "../assets/Konkursi - Punetor Fizik - Germi.pdf"
import punetorFizikGreen from "../assets/grayImage.jpg"
import punetorFizikV from "../assets/Konkurs - Punëtorë Fizik - Varrezat.pdf"
import greeningSurface from "../assets/greeningSurface.jpg"
import kryepunetor from "../assets/Konkurs - Kryepuntor ne ngritjen sipërfaqeve gjelbruese 10.05.2024.pdf"
import thirrjePerAplikime from "../assets/THIRRJE PËR APLIKIM PËR SPONZORIZIM PËR PROJEKTE NË FUSHËN E SPORTIT, KULTURËS, MJEDISIT DHE ARSIMIT-1.pdf"
import sport from "../assets/action-activity-adult-262524.jpg"
import shpallja25 from "../assets/Konkurs -31.05.2024 Punëtorë Fizik - Varrezat.pdf"
import latest from "../assets/latest.jpg"
import shpallja26 from "../assets/14.06.2024 Konkurs - Kryepuntor ne ngritjen sipërfaqeve gjelbruese 10.05.2024.pdf"
import shpallja27 from "../assets/14.06.2024 Konkurs - Zyrtar Koordinues QTQE.pdf"
import dogMan from "../assets/dogAndMan.jpg"
import shpallja28 from "../assets/21.06.2024 Konkurs - Kryepuntor ne Qendren e Kompostimit.pdf"
import kompostimi from "../assets/3.jpg"
import shpallja29 from "../assets/Konkurs Konsulent Ligjor.pdf"
import lawConculent from "../assets/lawConculent.jpg"
import shpallja30 from "../assets/Konkursi - Arkivist Alb-Srb.pdf"
import archive from "../assets/archive.jpg"
import shpallja31 from "../assets/Konkursi punëtor fizik për kapjen e qenëve endacak 09.07.2024.pdf"
import shpallja32 from "../assets/18.07.2024 Konkurs - Punetor fizik ne ngritjen sipërfaqeve gjelbruese.pdf"
import shpallja33 from "../assets/Kultivuesit e luleve dhe bimëve dekorative në sera dhe ambient të jashtëm.pdf"
import shpallja34 from "../assets/Depoist Konkursi Depoist 20.08.2024 i perkthyer (3).pdf"
import shpallja35 from "../assets/22.08. 2024 Konkurs - Punetor fizik ne ngritjen sipërfaqeve gjelbruese 3.pdf"
import shpallja36 from "../assets/29.08.2024 Asistent Administrativ Qendra e Qeve srb.pdf"
import shpallja37 from "../assets/30.08.2024.Zyrtar Burimeve Njërzore (2).pdf"
import shpallja38 from "../assets/05.09.2024 Konkurs - Kryepuntor ne Germi.pdf"
import shpallja39 from "../assets/05.09.2024 Roje Pylli Pylltar.pdf"
import shpallja40 from "../assets/Perkthim Keshilltar i Ndërmarrjes 09.09.2024 i perkthyer.pdf"
import shpallja41 from "../assets/09.09.2024Konkurs - Punetor fizik ne ngritjen sipërfaqeve gjelbruese.pdf"
import shpallja42 from "../assets/20241004162239010.pdf"
import it from "../assets/IT-1.jpg"
import shpallja43 from "../assets/KONSULENT IT.pdf"
import photograph from "../assets/photograph.jpg"
import shpallja44 from "../assets/EKSPERT I JASHTEM.pdf"
import shpallja45 from "../assets/Konkursi Roje germi 30.10.2024 kk.pdf"
import shpallja46 from "../assets/Shpallje për trajnim.pdf";
import shpallja47 from "../assets/Praktikant  Automekanik 21.11.2024.pdf";
import mechanic from "../assets/mechanic.jpg"
import shpallja48 from "../assets/20241203142943016.pdf"
import operations from "../assets/operations-manager.jpg"
import shpallja49 from "../assets/20241211111630312.pdf"
import cosulent from "../assets/assistant-vs-secretary-1024x683.jpg"
import shpallja50 from "../assets/20241212142711843.pdf"
import shpallja51 from "../assets/20241213151202043.pdf"
import sera from "../assets/sera.jpg"
const shpalljet = [
  {
    id:455,
    konkurs: "KONKURS",
    title: "Kultivuesit e luleve dhe bimëve dekorative në sera dhe ambient të jashtëm",
    description: 'Drejtor i Operativës',
    background: '#FFF6E7',
    buttonColor: "#6ADB84",
    textColor: "#003249",
    adsImage: sera,
    publicationDate: '13.12.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja51
  },
  {
    id:454,
    konkurs: "KONKURS I JASHTËM",
    title: "Roje i objektit në Njësinë e Gërmisë",
    description: 'Drejtor i Operativës',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: germiaPark,
    publicationDate: '12.12.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja50
  },
  {
    id:453,
    konkurs: "KONKURS I JASHTËM",
    title: "Këshilltar i Ndërmarrjes",
    description: 'Drejtor i Operativës',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: cosulent,
    publicationDate: '10.12.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja49
  },
  {
    id:452,
    konkurs: "KONKURS",
    title: "Drejtor i Operativës",
    description: 'Drejtor i Operativës',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: operations,
    publicationDate: '03.12.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja48
  },
  {
    id:45,
    konkurs: "KONKURS",
    title: "Praktikant - Automekanik",
    description: 'Praktikant - Automekanik',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: mechanic,
    publicationDate: '21.11.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja47
  },
  {
    id:451,
    konkurs: "Shpallje Publike",
    title: "Thirrje për kompanitë e interesuara për ofrimin e trajnimeve në krasitje dhe hortikulturë",
    description: 'Thirrje për kompanitë e interesuara për ofrimin e trajnimeve në krasitje dhe hortikulturë',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: greenAttacks,
    publicationDate: '18.11.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    // aplikacioniNenBetim: aplikacioni,
    // aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja46
  },
  {
    id:450,
    konkurs: "KONKURS I JASHTËM",
    title: "Roje i objektit në Njësinë e Gërmisë",
    description: 'Roje i objektit në Njësinë e Gërmisë',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: germiaPark,
    publicationDate: '30.10.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja45
  },
  {
    id:45,
    konkurs: "KONKURS - për Ekspert të Jashtëm",
    title: "Ekspert/e/i e Jashtëm - Fotograf për Qendrën për Trajtimin e Qenëve Endacak (QTQE)",
    description: 'Mirëmbajtës i Higjienës',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: photograph,
    publicationDate: '21.10.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja44
  },
  {
    id:44,
    konkurs: "KONKURS - për Konsulent të Jashtëm",
    title: "Konsulent për strategjinë e TI-së dhe Dixhitalizimit",
    description: 'Mirëmbajtës i Higjienës',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: it,
    publicationDate: '21.10.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja43
  },
  {
    id:43,
    konkurs: "KONKURS I JASHTËM",
    title: "Mirëmbajtës i Higjienës",
    description: 'Mirëmbajtës i Higjienës',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: germiaPark,
    publicationDate: '04.10.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja42
  },
  {
    id:42,
    konkurs: "KONKURS I JASHTËM",
    title: "Punëtor fizik në ngritjen dhe mirëmbajtjen e sipërfaqeve të gjelbra",
    description: 'Punëtor fizik në ngritjen dhe mirëmbajtjen e sipërfaqeve të gjelbra',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: greeningSurface,
    publicationDate: '09.09.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja41
  },
  {
    id:41,
    konkurs: "KONKURS I JASHTËM",
    title: "Këshilltar i Ndërmarrjes",
    description: 'Këshilltar i Ndërmarrjes',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: lawConculent,
    publicationDate: '09.09.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja40
  },
  {
    id:40,
    konkurs: "KONKURS I BRENDSHËM",
    title: "Roje e pyjeve- Pylltar",
    description: 'Roje e pyjeve- Pylltar',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: germiaPark,
    publicationDate: '05.09.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja39
  },
  {
    id:39,
    konkurs: "KONKURS I BRENDSHËM",
    title: "Kryepunëtor në mirëmbajtjen dhe mbrojtjen e Peizazhit natyrorë të Gërmisë",
    description: 'Kryepunëtor në mirëmbajtjen dhe mbrojtjen e Peizazhit natyrorë të Gërmisë',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: greeningSurface,
    publicationDate: '05.09.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja38
  },
  {
    id:38,
    konkurs: "KONKURS I BRENDSHËM",
    title: "Zyrtar/e në Njësinë e Burimeve Njerëzore",
    description: 'Zyrtar/e në Njësinë e Burimeve Njerëzore',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: greeningSurface,
    publicationDate: '30.08.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja37
  },
  {
    id:37,
    konkurs: "KONKURS",
    title: "Asistent Administrativ",
    description: 'Asistent Administrativ',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: archive,
    publicationDate: '29.08.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja36
  },
  {
    id:36,
    konkurs: "KONKURS",
    title: "Punëtor fizik në ngritjen dhe mirëmbajtjen e sipërfaqeve të gjelbra",
    description: 'Punëtor fizik në ngritjen dhe mirëmbajtjen e sipërfaqeve të gjelbra',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: greeningSurface,
    publicationDate: '22.08.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja35
  },
  {
    id:35,
    konkurs: "KONKURS",
    title: "Depoist në objektin administrativ",
    description: 'Depoist në objektin administrativë',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: lawConculent,
    publicationDate: '20.08.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja34
  },
  {
    id:34,
    konkurs: "KONKURS",
    title: "Kultivuesit e luleve dhe bimëve dekorative në sera dhe ambient të jashtëm",
    description: 'Kultivuesit e luleve dhe bimëve dekorative në sera dhe ambient të jashtëm',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: greenAttacks,
    publicationDate: '22.07.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja33
  },
  {
    id:33,
    konkurs: "KONKURS",
    title: "Punëtor fizik në ngritjen dhe mirëmbajtjen e sipërfaqeve të gjelbra",
    description: 'Punëtor fizik në ngritjen dhe mirëmbajtjen e sipërfaqeve të gjelbra',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: greenAttacks,
    publicationDate: '18.07.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja32
  },
  {
    id:32,
    konkurs: "KONKURS",
    title: "Punëtor fizik për kapjen e qenëve endacak",
    description: 'Punëtor fizik për kapjen e qenëve endacak',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: dog,
    publicationDate: '09.07.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja31
  },
  {
    id:31,
    konkurs: "KONKURS I BRENDSHËM",
    title: "Arkivist/e",
    description: '',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: archive,
    publicationDate: '01.07.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja30
  },
  {
    id:30,
    konkurs: "KONKURS",
    title: "Konsulent ligjor",
    description: '',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: lawConculent,
    publicationDate: '27.06.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja29
  },
  {
    id:29,
    konkurs: "KONKURS",
    title: "Kryepunëtor në Qendrën e Kompostimit",
    description: '',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: kompostimi,
    publicationDate: '21.06.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja28
  },
  {
    id:28,
    konkurs: "KONKURS",
    title: "Zyrtar Koordinues në Qendrën e Qenve Endacak",
    description: '',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: dogMan,
    publicationDate: '14.06.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja27
  },
  {
    id:27,
    konkurs: "KONKURS",
    title: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    description: '',
    background: '#002009',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: greeningSurface,
    publicationDate: '14.06.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja26
  },
  {
    id:25,
    konkurs: "KONKURS",
    title: "Punëtor fizik",
    description: '',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: latest,
    publicationDate: '31.05.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Punëtor fizik për kapjen e qenëve (2 Pozita)",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: shpallja25
  },
  {
    id:24,
    konkurs: "Thirrje për aplikim",
    title: "THIRRJE PËR APLIKIME PËR SPONZORIZIM PËR PROJEKTE NË FUSHËN E SPORTIT, KULTURËS, MJEDISIT DHE ARSIMIT.",
    description: '',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: sport,
    publicationDate: '22.05.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Punëtor fizik për kapjen e qenëve (2 Pozita)",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, aplikimin mund ta bëjnë në mënyrë Elektronike (online) përmes emailit  zyrtar : aplikimet@gjelber.com. Kandidatët duhet të dërgojnë te gjithë dokumentacionin e skenuar nëpërmjet emailit. Të gjithë kandidatët që i plotësojnë kriteret e verifikimit paraprak do t’i nënshtrohen testimit me shkrim dhe intervistimit. Poentimi nga Komisioni për Rekrutim do të bëhet si në vijim:- Jetë përshkrimi (CV) i kandidatit vlerësohet deri në dhjetë (10) pikë- Testimi me shkrim deri në shtatëdhjetë (70) pikë dhe - Intervistimi deri në njëzetë (20) pikë.",
    rekrutimi: "Procedura e rekrutimit pritet të zgjasë nga data 14.05.2024 deri më 14.06.2024 (ky afat është i përafërt dhe mund të jetë subjekt i ndryshimeve siç specifikon legjislacioni në fuqi.). Shpallja është e hapur që nga data 14.05.2024 deri më 29.05.2024 në orën 15:00. Për informata më të hollësishme mund të kontaktoni Divizionin e Burimeve Njerëzore të NPL”Gjelbër” SH.A.",
    aplikimiPDF: thirrjePerAplikime
  },
  {
    id:15,
    konkurs: "Konkurs",
    title: "Punëtor fizik për kapjen e qenëve (2 POZITA)",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A rishpall konkurs për...',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: dog,
    publicationDate: '14.05.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, Vendimit të Komisionit me numër protokolli 896 të datës 27.03.2024 si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” rishpall këtë:",
    titulliPozites: "Punëtor fizik për kapjen e qenëve (2 Pozita)",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "Shkolla e Mesme",
    patentShofer: "Patentë shofer - Kategoria B",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr direktiva nga Veterinari dhe Drejtori i Operativës",
    detyra2: "kryen shërbime për kapjen e qenëve endacak dhe i transporton në qendër",
    detyra3: "sistemon kafshët në kafaze dhe kujdeset për mirëqenien e tyre",
    detyra4: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra5: "obligohet të lëviz nga njësia në njësi- zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të drejtorit të operativës",
    detyra6: "për punën e tij i përgjigjet Veterinarit dhe Drejtorit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, aplikimin mund ta bëjnë në mënyrë Elektronike (online) përmes emailit  zyrtar : aplikimet@gjelber.com. Kandidatët duhet të dërgojnë te gjithë dokumentacionin e skenuar nëpërmjet emailit. Të gjithë kandidatët që i plotësojnë kriteret e verifikimit paraprak do t’i nënshtrohen testimit me shkrim dhe intervistimit. Poentimi nga Komisioni për Rekrutim do të bëhet si në vijim:- Jetë përshkrimi (CV) i kandidatit vlerësohet deri në dhjetë (10) pikë- Testimi me shkrim deri në shtatëdhjetë (70) pikë dhe - Intervistimi deri në njëzetë (20) pikë.",
    rekrutimi: "Procedura e rekrutimit pritet të zgjasë nga data 14.05.2024 deri më 14.06.2024 (ky afat është i përafërt dhe mund të jetë subjekt i ndryshimeve siç specifikon legjislacioni në fuqi.). Shpallja është e hapur që nga data 14.05.2024 deri më 29.05.2024 në orën 15:00. Për informata më të hollësishme mund të kontaktoni Divizionin e Burimeve Njerëzore të NPL”Gjelbër” SH.A.",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: kapjeQenve
  },
  {
    id:20,
    konkurs: "Konkurs",
    title: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#004653',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: greeningSurface,
    publicationDate: '10.05.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe Kërkesës me nr Arkivi 1294 të dt 10.05.2024 NPL ”Gjelbër” shpall këtë:",
    titulliPozites: "Kryepunëtor në ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 8",
    vendi: "Prishtinë",
    diploma: "shkolla e mesme",
    pervojaPunes: "1 vit përvojë pune",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "organizon dhe udhëheqë punët në ngritjen, mirëmbajtjen dhe rregullimin e sipërfaqeve të gjelbëruara;",
    detyra2: "bashkëpunon me komisionet e përcjelljes së punëve dhe komisionet për pranimin e punëve përmes ditarëve të punës të përcaktuar me kontratë në mes të hortikulturës dhe kontraktuesit,",
    detyra3: "me urdhëresë të eprorit të bashkëpunon me kryepunëtorin në prodhim në mbjelljen e bimëve të prodhuara;",
    detyra4: "organizon të gjitha fazat e punës në mirëmbajtjen e sipërfaqeve të gjelbëruara ;",
    detyra5: "kositja, ujitjen, prashitjen si dhe punët tjera në mirëmbajtjen duke u bazuar në elementet kontraktuese të punës;",
    detyra6: "i mbanë ditarët e punës duke bashkëpunuar me komisionet për vërtetimin e punëve të kryera;,",
    detyra7: "mban evidencën e punëtorëve,",
    detyra8: "ditarët e punës të vërtetuar nga komisioni mbikëqyrës dhe evidencën e punëtorëve në fund të çdo muaji ia dorëzon koordinatorit të njësisë",
    detyra9: "i koordinon punët me vozitësit e automjeteve për nevojat e ujitjes si dhe punët tjera si në kositje dhe në kultivimin dhe mirëmbajtjen e tërësishme të sipërfaqeve gjelbëruese",
    detyra10: "paraqet me kohë të gjitha defektet e mjeteve dhe makinerive tek personi kompetent",
    detyra11: "obligohet të lëviz nga njësia në njësi, zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të Shefit të Operativës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, aplikimin mund ta bëjnë në mënyrë Elektronike (online) përmes emailit  zyrtar : aplikimet@gjelber.com. Kandidatët duhet të dërgojnë te gjithë dokumentacionin e skenuar nëpërmjet emailit. Të gjithë kandidatët që i plotësojnë kriteret e verifikimit paraprak do t’i nënshtrohen testimit me shkrim dhe intervistimit. Poentimi nga Komisioni për Rekrutim do të bëhet si në vijim:- Jetë përshkrimi (CV) i kandidatit vlerësohet deri në dhjetë (10) pikë- Testimi me shkrim deri në shtatëdhjetë (70) pikë dhe - Intervistimi deri në njëzetë (20) pikë.",
    rekrutimi: "Procedura e rekrutimit pritet të zgjasë nga data 10.05.2024 deri më 10.06.2024 (ky afat është i përafërt dhe mund të jetë subjekt i ndryshimeve siç specifikon legjislacioni në fuqi.)",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: kryepunetor
  },
  {
    id:19,
    konkurs: "Konkurs",
    title: "Punëtor fizik - Varrezat (3 POZITA)",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#006646',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: punetorFizikGreen,
    publicationDate: '10.05.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe Kërkesës me nr Arkivi 1294 të dt 10.05.2024 NPL ”Gjelbër” shpall këtë:",
    titulliPozites: "Punëtor fizik - Varrezat (3 POZITA)",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 13",
    vendi: "Prishtinë",
    diploma: "shkolla e mesme",
    pervojaPunes: "1 vit përvojë pune",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "kryen punët fizike në mihjen e varrit, lëshimin e kufomës, mbylljen e varrit dhe punët në mirëmbajtjen e varrezave;",
    detyra2: "bënë ekskumimin, mihjen, nxjerrjen e kufomës, mbylljen e varrit dhe dezinfektimin e vendit;",
    detyra3: "kujdeset për higjienën në morg, për pajimet dhe instalimet, bënë bartjen e kufomës prej morgut dhe vendvarrimit;",
    detyra4: "është i obliguar të këtë sjellje korrekte duke respektuar rregullat e ceremonive të varrimit;",
    detyra5: "kryen te gjitha punët në mirëmbajtjen e gjelbërimit në sipërfaqet e varrezave",
    detyra6: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresës së eprorit,",
    detyra7: "obligohet të lëvizë nga njësia në njësi, zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të Drejtorit të Operativës",
    detyra8: "për punën e 􀆟j i përgjigjet Kryepunëtorit dhe Udhëheqësit të Njësisë",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, aplikimin mund ta bëjnë në mënyrë Elektronike (online) përmes emailit  zyrtar : aplikimet@gjelber.com. Kandidatët duhet të dërgojnë te gjithë dokumentacionin e skenuar nëpërmjet emailit. Të gjithë kandidatët që i plotësojnë kriteret e verifikimit paraprak do t’i nënshtrohen testimit me shkrim dhe intervistimit. Poentimi nga Komisioni për Rekrutim do të bëhet si në vijim:- Jetë përshkrimi (CV) i kandidatit vlerësohet deri në dhjetë (10) pikë- Testimi me shkrim deri në shtatëdhjetë (70) pikë dhe - Intervistimi deri në njëzetë (20) pikë.",
    rekrutimi: "Procedura e rekrutimit pritet të zgjasë nga data 10.05.2024 deri më 10.06.2024 (ky afat është i përafërt dhe mund të jetë subjekt i ndryshimeve siç specifikon legjislacioni në fuqi.)",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: punetorFizikV
  },
  {
    id:18,
    konkurs: "Konkurs",
    title: "Punëtor fizik në mirëmbajtjen dhe mbrojtjen e Peizazhit të Gërmisë (2 POZITA)",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: germiaPark,
    publicationDate: '10.05.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe Kërkesës me nr Arkivi 1294 të dt 10.05.2024 NPL ”Gjelbër” shpall këtë:",
    titulliPozites: "Punëtor fizik në mirëmbajtjen dhe mbrojtjen e Peizazhit të Gërmisë (2 POZITA)",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 13",
    vendi: "Prishtinë",
    diploma: "shkolla e mesme",
    pervojaPunes: "1 vit përvojë pune",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr pjesë të drejtpërdrejtë në të gjitha punët rreth mbrojtjes së peizazhit natyror të mbrojtur të “Gërmisë”,",
    detyra2: "kryen të gjitha punët në rregullimin dhe mirëmbajtjen e sipërfaqeve të gjelbëruara, si dhe mbrojtjen e peizazhit natyror të mbrojtur të “Gërmisë”,",
    detyra3: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresë të eprorit,",
    detyra4: "obligohet të lëviz nga njësia në njësi, zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të Drejtorit të Operativës",
    detyra5: "për punën e tij i përgjigjet Kryepunëtorit",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, aplikimin mund ta bëjnë në mënyrë Elektronike (online) përmes emailit  zyrtar : aplikimet@gjelber.com. Kandidatët duhet të dërgojnë te gjithë dokumentacionin e skenuar nëpërmjet emailit. Të gjithë kandidatët që i plotësojnë kriteret e verifikimit paraprak do t’i nënshtrohen testimit me shkrim dhe intervistimit. Poentimi nga Komisioni për Rekrutim do të bëhet si në vijim:- Jetë përshkrimi (CV) i kandidatit vlerësohet deri në dhjetë (10) pikë- Testimi me shkrim deri në shtatëdhjetë (70) pikë dhe - Intervistimi deri në njëzetë (20) pikë.",
    rekrutimi: "Procedura e rekrutimit pritet të zgjasë nga data 10.05.2024 deri më 10.06.2024 (ky afat është i përafërt dhe mund të jetë subjekt i ndryshimeve siç specifikon legjislacioni në fuqi.)",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: peisazhiGermise
  },
  {
    id:17,
    konkurs: "Konkurs",
    title: "Roje e pyjeve- Pylltar",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#003249',
    buttonColor: "#003249",
    textColor: "#FFF6E7",
    adsImage: forest,
    publicationDate: '10.05.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe Kërkesës me nr Arkivi 1294 të dt 10.05.2024 NPL ”Gjelbër” shpall këtë:",
    titulliPozites: "Roje e pyjeve- Pylltar",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 10",
    vendi: "Prishtinë",
    diploma: "shkolla e mesme",
    pervojaPunes: "1 vit përvojë pune",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "kryen të gjitha punët në ruajtjen dhe mbrojtjen e pyjeve, në pajtim me ligjin mbi pyjet,",
    detyra2: "kujdeset për ruajtjen, kul􀆟vimin dhe mbrojtjen e egërsirave dhe pengon gjuetinë e paligjshme,",
    detyra3: "përpilon dhe dërgon fletëparaqitjet për të gjitha punët e paligjshme në pyje,",
    detyra4: "ndihmon gjatë ndërmarrjes së masave konkrete në kultivimin dhe ngritjen e pyjeve si dhe për shfrytëzimin e tyre, merre pjesë në fikjen e zjarreve të pyjeve,",
    detyra5: "ruan kulturat pyjore dhe produktet e gatshme të pyjeve,",
    detyra6: "sipas urdhëresës së udhëheqësit të drejtpërdrejtë bënë inkasimin e të ardhurave nga produktet kryesore dhe sekondare të pyjeve,",
    detyra7: "çdo tre muaj paraqet raport me shkrim për këto punë dhe për dëmet e shkaktuara në rajonet e tyre,",
    detyra8: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresë të eprorit,",
    detyra9: "obligohet të lëvizë nga njësia në njësi, zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të Drejtorit të Opera􀆟vës për punën e tij i përgjigjet Kryepunëtorit dhe Udhëheqësit të Njësisë",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, aplikimin mund ta bëjnë në mënyrë Elektronike (online) përmes emailit  zyrtar : aplikimet@gjelber.com. Kandidatët duhet të dërgojnë te gjithë dokumentacionin e skenuar nëpërmjet emailit. Të gjithë kandidatët që i plotësojnë kriteret e verifikimit paraprak do t’i nënshtrohen testimit me shkrim dhe intervistimit. Poentimi nga Komisioni për Rekrutim do të bëhet si në vijim:- Jetë përshkrimi (CV) i kandidatit vlerësohet deri në dhjetë (10) pikë- Testimi me shkrim deri në shtatëdhjetë (70) pikë dhe - Intervistimi deri në njëzetë (20) pikë.",
    rekrutimi: "Procedura e rekrutimit pritet të zgjasë nga data 10.05.2024 deri më 10.06.2024 (ky afat është i përafërt dhe mund të jetë subjekt i ndryshimeve siç specifikon legjislacioni në fuqi.)",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: pylltar
  },
  {
    id:16,
    konkurs: "Konkurs",
    title: "Punëtor fizik në ngritjen dhe mirëmbajtjen e sipërfaqeve të gjelbra (8 POZITA)",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: greenAttacks,
    publicationDate: '10.05.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë: 16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe Kërkesës me nr Arkivi 1294 të dt 10.05.2024 NPL ”Gjelbër” shpall këtë:",
    titulliPozites: "Punëtor fizik në ngritjen dhe mirëmbajtjen e sipërfaqeve të gjelbra (8 POZITA)",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 13",
    vendi: "Prishtinë",
    diploma: "shkolla e mesme",
    pervojaPunes: "1 vit përvojë pune",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "merr pjesë të  drejtpërdrejtë në të gjitha punët rreth rregullimit dhe mirëmbajtjes së sipërfaqeve të gjelbëruara,",
    detyra2: "kryen të gjitha punët në ngritjen, rregullimin dhe mirëmbajtjen e sipërfaqeve të gjelbëruara,",
    detyra3: "kryen dhe punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresë të eprorit,",
    detyra4: "obligohet të lëvizin nga njësia në njësi, zona të tjera, në raste të veçanta dhe sipas kërkesës së punës me urdhër të Drejtorit të Operativës",
    detyra5: "për punën e tij i përgjigjet Kryepunëtorit dhe Udhëheqësit të Njësisë",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, aplikimin mund ta bëjnë në mënyrë Elektronike (online) përmes emailit  zyrtar : aplikimet@gjelber.com. Kandidatët duhet të dërgojnë te gjithë dokumentacionin e skenuar nëpërmjet emailit. Të gjithë kandidatët që i plotësojnë kriteret e verifikimit paraprak do t’i nënshtrohen testimit me shkrim dhe intervistimit. Poentimi nga Komisioni për Rekrutim do të bëhet si në vijim:- Jetë përshkrimi (CV) i kandidatit vlerësohet deri në dhjetë (10) pikë- Testimi me shkrim deri në shtatëdhjetë (70) pikë dhe - Intervistimi deri në njëzetë (20) pikë.",
    rekrutimi: "Procedura e rekrutimit pritet të zgjasë nga data 10.05.2024 deri më 10.06.2024 (ky afat është i përafërt dhe mund të jetë subjekt i ndryshimeve siç specifikon legjislacioni në fuqi.)",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: mirembajtjeSiperfaqeveGjelbra
  },
  {
    id:5,
    konkurs: "Konkurs",
    title: "Teknik Veterinar",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#002009',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    adsImage: veterinarian,
    publicationDate: '27.03.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë:16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe Kërkesës me nr Arkivi 888 të dt 27.03.2024 NPL”Gjelbër” Rishpall këtë:",
    titulliPozites: "Teknik Veterinar",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 7",
    vendi: "Prishtinë",
    diploma: "viti i fundit i studimeve në Fakultetin e Veterinarisë ",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "Asiston Veterinarin në organizimin e punëve në qendrën për trajnimin e qeneve ",
    detyra2: "Bën pranimin, regjistrimin dhe pastrmin e kafshës në qendër ",
    detyra3: "Sinkronizon veprimet  me planin operativ të hartuar nga Veterinari ",
    detyra4: "Vaksinon dhe vendos qipin në kafshë",
    detyra5: "Sigurohet për ushqimin e kafshëve ",
    detyra6: "Harmonizon dhe programon bilances materiale dhe shpenezuese medicinale për Njësinë punuese ",
    detyra7: "Kryen edhe punë të tjera me urdhëresë të eprorit ",
    detyra8: "Për punen e tij i përgjigjet eprorit",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, aplikimin mund ta bëjnë në mënyrë Elektronike (online) përmes emailit  zyrtar : aplikimet@gjelber.com. Kandidatët duhet të dërgojnë te gjithë dokumentacionin e skenuar nëpërmjet emailit. Të gjithë kandidatët që i plotësojnë kriteret e verifikimit paraprak do t’i nënshtrohen testimit me shkrim dhe intervistimit. Poentimi nga Komisioni për Rekrutim do të bëhet si në vijim:- Jetë përshkrimi (CV) i kandidatit vlerësohet deri në dhjetë (10) pikë- Testimi me shkrim deri në shtatëdhjetë (70) pikë dhe - Intervistimi deri në njëzetë (20) pikë.",
    rekrutimi: "Procedura e rekrutimit pritet të zgjasë nga data 11.04.2024 deri më 15.05.2024 (ky afat është i përafërt dhe mund të jetë subjekt i ndryshimeve siç specifikon legjislacioni në fuqi.). Shpallja është e hapur që nga data 27.03.2024 deri më 11.04.2024 në orën 15:00. Për informata më të hollësishme mund të kontaktoni Divizionin e Burimeve Njerëzore të NPL”Gjelbër” SH.A.",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: teknikveterinar
  },
  
  {
    id:12,
    konkurs: "Konkurs",
    title: "Zyrtar/e i/e Lartë Ligjore",
    adsImage: law,
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#FFF6E7',
    buttonColor: "#006646",
    textColor: "#006646",
    imageUrl: image2,
    publicationDate: '07.03.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë:16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe vendimit për anulimin e konkursit me nr 308 të dt 01.02.2024   mardhënien kontraktuale dhe avansim në detyrë në ndërmarrje me nr.1338 të datë:20.06.2023, NPL”Gjelbër” Shpall këtë:",
    titulliPozites: "Zyrtar/e i/e Lartë Ligjore",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës kategoria 6",
    vendi: "Prishtinë",
    diploma: "Diplomë universitare-Fakulteti Juridik",
    patentShofer: "",
    pervojaPunes: "3 Vite përvojë pune",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "udhëheq punën e gjithmbarshme të sektorit dhe ndihmon mbikëqyrësin në caktmin e objektivave dhe hartmin e planit të punës për përmbushjen e këtyre objektivave;",
    detyra2: "menaxhon me stafin e sektorit dhe bënë ndarjen e detyrave tek varësit e tij, ofron udhëzime dhe monitoron punën e stafit për të siguruar produkte dhe shërbime cilësore;",
    detyra3: "shqyrton propozimet e rregulloreve dhe akteve tjera që i propozohen",
    detyra4: "asiston në hartimin e rregulloreve, urdhëresave administrative si dhe aktet tjera juridike",
    detyra5: "siguron ndihmë juridike, shërbime dhe këshilla për drejtoritë, zyrat dhe departamentet e ndërmarrjes",
    detyra6: "siguron që projekt rregulloret e propozuara dhe të hartuara të jenë në përputhje me standardet e ligjet përkatëse dhe politikat dhe statutin e ndërmarrjes",
    detyra7: "siguron përfaqësimin dhe mbrojtjen e interesave të ndërmarrjes në procedurat gjyqësore dhe jokontestimore dhe bën vlerësim të rregull të stafit nën mbikëqyrje të tij dhe përkrahë zhvillimin e tyre përmes trajnimeve për të siguruar kryerjen e detyrave të tyre në nivel me standardet e kërkuara",
    detyra8: "kryen dhe punë të tjera sipas urdhrit të Sekretarit si dhe për punën e tij/saj i përgjigjet Sekretarit",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, formularin zyrtar mund ta marrin në Arkivën e Ndërmarrjes NPL”Gjelbër” SH.A  apo ta shkarkojnë nga web faqja e internetit në adresën www.gjelber.com. Dokumentacionin mund ta paraqesin në kopje fizike në Divizionin e Burimeve Njerëzore në adresën, rr. Tahir Zajmi, pn në Prishtinë, apo përmes Postës së Kosovës. Kandidatët të cilët konkurojnë duhet të shënojnë saktë pozitën në të cilën aplikojnë, adresën, numrin e telefonit kontaktues si dhe email adresën. Aplikacionet dhe dokumentacioni i pakompletuar sipas kërkesave të përcaktuara në këtë shpallje si dhe ato që arrijnë pas skadimit të afatit të konkursit, nuk do të shqyrtohen. Shpallja është e hapur që nga data 07.03.2024 - 22.03.2024.",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: zyrtareLigjore,
    aplikimiPDFSrb: zyrtareLigjoreSrb
  },
  {
    id:11,
    konkurs: "Konkurs",
    title: "VOZITËS",
    adsImage: driver,
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#006646',
    buttonColor: "#FFF6E7",
    textColor: "#FFF6E7",
    imageUrl: image2,
    publicationDate: '07.03.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë:16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe vendimit për anulimin e konkursit me nr 308 të dt 01.02.2024   mardhënien kontraktuale dhe avansim në detyrë në ndërmarrje me nr.1338 të datë:20.06.2023, NPL”Gjelbër” Shpall këtë:",
    titulliPozites: "VOZITËS",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës 12",
    vendi: "Prishtinë",
    diploma: "Shkolla e mesme",
    patentShofer: "kategoria B",
    pervojaPunes: "2 vite përvojë pune",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "ofron shërbime të vozitjes për stafin e ndërmarrjes në bazë të rregullave të komunikacionit;",
    detyra2: "bënë bartjen e drejtpërdrejtë të postes, dërgesave urgjente;",
    detyra3: "kujdeset për mirëmbajtjen e automjetit;",
    detyra4: "plotëson formularët lidhur me veturën zyrtare; ",
    detyra5: "i dorëzon raportin me shkrim zyrtarit kompetent lidhur me shpenzimet dhe mirëmbajtjen;",
    detyra6: "Udhëheqësi i Administratës mund ta ngarkojë me detyra tjera sipas nevojës;",
    detyra7: "në rast të mungesës së zyrtarëve tjerë me urdhëresë̈ mund t’i zëvendësojë dhe të kryejë detyra të tjera",
    detyra8: "i përgjigjet Udhëheqësit të Administratës.",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, formularin zyrtar mund ta marrin në Arkivën e Ndërmarrjes NPL”Gjelbër” SH.A  apo ta shkarkojnë nga web faqja e internetit në adresën www.gjelber.com. Dokumentacionin mund ta paraqesin në kopje fizike në Divizionin e Burimeve Njerëzore në adresën, rr. Tahir Zajmi, pn në Prishtinë, apo përmes Postës së Kosovës. Kandidatët të cilët konkurojnë duhet të shënojnë saktë pozitën në të cilën aplikojnë, adresën, numrin e telefonit kontaktues si dhe email adresën. Aplikacionet dhe dokumentacioni i pakompletuar sipas kërkesave të përcaktuara në këtë shpallje si dhe ato që arrijnë pas skadimit të afatit të konkursit, nuk do të shqyrtohen. Shpallja është e hapur që nga data 07.03.2024 - 22.03.2024.",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: vozites,
    aplikimiPDFSrb: vozitesSrb
  },
  {
    id:4,
    konkurs: "Konkurs",
    title: "Mirëmbajtës i Higjienës",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A rishpall konkurs për...',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    imageUrl: image2,
    publicationDate: '05.03.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë:16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe vendimit për anulimin e konkursit me nr 308 të dt 01.02.2024 mardhënien kontraktuale dhe avansim në detyrë në ndërmarrje me nr.1338 të datë:20.06.2023, NPL”Gjelbër” Rishpall konkursin 363 të datës 07.02.2024:",
    titulliPozites: "Mirëmbajtës i Higjienës",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 13",
    vendi: "Prishtinë",
    diploma: "Shkolla Fillore",
    pervojaPunes: "1 (një) vit përvojë pune",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "mirëmban pastërtinë e objektit të administratës;",
    detyra2: "mirëmban inventarin e gjithmbarshëm dhe lulet;",
    detyra3: "për punën e tij i përgjigjet Udhëheqësit të Administratës",
    detyra4: "pastrimin e zyrave dhe të objektit në përgjithësi,",
    detyra5: "pastrimin e tualeteve,",
    detyra6: "pastrimin e oborrit dhe hapësirës përreth objektit të ndërmarrjes,",
    detyra7: "kryen punë të tjera duke u bazuar në veprimtarinë e ndërmarrjes dhe urdhëresat e eprorit,",
    detyra8: "për punën e tij/saj i përgjigjet Udhëheqësit të Administratës",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, formularin zyrtar mund ta marrin në Arkivën e Ndërmarrjes NPL”Gjelbër” SH.A  apo ta shkarkojnë nga web faqja e internetit në adresën www.gjelber.com. Dokumentacionin mund ta paraqesin në kopje fizike në Divizionin e Burimeve Njerëzore në adresën, rr. Tahir Zajmi, pn në Prishtinë, apo përmes Postës së Kosovës. Kandidatët të cilët konkurojnë duhet të shënojnë saktë pozitën në të cilën aplikojnë, adresën, numrin e telefonit kontaktues si dhe email adresën. 05.03.2024 - 20.03.2024",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
    aplikimiPDF: mirembajtjeHigjiene
  },
  {
    id:10,
    konkurs: "Konkurs",
    title: "Ekspert/e i/e Jashtëm/e për Strategji në Marrëdhënie me Publikun",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    imageUrl: image2,
    publicationDate: '14.02.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë:16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe vendimit për anulimin e konkursit me nr 308 të dt 01.02.2024   mardhënien kontraktuale dhe avansim në detyrë në ndërmarrje me nr.1338 të datë:20.06.2023, NPL”Gjelbër” Shpall këtë:",
    titulliPozites: "Ekspert/e i/e Jashtëm/e për Strategji në Marrëdhënie me Publikun",
    kontrata: "6 muaj, me mundësi vazhdimi ",
    orari: "40 orë në javë",
    paga: "Niveli 4",
    vendi: "Prishtinë",
    diploma: "diplomë univerzitare",
    patentShofer: "",
    pervojaPunes: "përvojë 3 vjeçare",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "zhvillimi dhe implementimi i strategjisë të mediave sociale në përputhje me objektivat e përgjithshme të ndërmarrjes;",
    detyra2: "identifikimi i audiencave të synuara dhe draftimi i përmbajtjes për të përmbushur interesat dhe nevojat e tyre ndërlidhur me promovimin e ndërmarrjes;",
    detyra3: "planifikimi dhe hartimin e fushatave në media sociale në lidhje me fushëveprimatrinë apo projektet specifike të ndërmarrjes;",
    detyra4: "aftësi për punë ekipore",
    detyra5: "aftësi të shprehura komunikimi",
    detyra6: "të ketë etikë në punë dhe integritet të lartë ",
    detyra7: "e preferuar njohja e gjuhës angleze ",
    detyra8: "-	informohet për ndryshimet në legjislacionin e punës dhe udhëzimet përkatëse -	përpunon raporte dhe statistika të departamenteve -	merr pjesë statistikave dhe departamenteve -	merr pjesë në hartimin e planit të biznesit të Ndërmarrjes në fushë e BNJ. -	kryen punë të tjera të caktuara nga Udhëheqësi i Njësisë, sipas fushës së kompetencës dhe pozitës -	i përgjigjet Udhëheqësit të Burimeve Njerëzore dhe Drejtorit të Administratës.",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, formularin zyrtar mund ta marrin në Arkivën e Ndërmarrjes NPL”Gjelbër” SH.A  apo ta shkarkojnë nga web faqja e internetit në adresën www.gjelber.com. Dokumentacionin mund ta paraqesin në kopje fizike në Divizionin e Burimeve Njerëzore në adresën, rr. Tahir Zajmi, pn në Prishtinë, apo përmes Postës së Kosovës. Kandidatët të cilët konkurojnë duhet të shënojnë saktë pozitën në të cilën aplikojnë, adresën, numrin e telefonit kontaktues si dhe email adresën. Aplikacionet dhe dokumentacioni i pakompletuar sipas kërkesave të përcaktuara në këtë shpallje si dhe ato që arrijnë pas skadimit të afatit të konkursit, nuk do të shqyrtohen. Shpallja është e hapur që nga data 14.02.2024 – 29.02.2024.",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,

  },
  {
    id:8,
    konkurs: "Konkurs",
    title: "Praktikant - Zyrtar/e në Njësinë e Burimeve Njerëzore",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#006646',
    buttonColor: "#FFF6E7",
    textColor: "#FFF6E7",
    imageUrl: image2,
    publicationDate: '14.02.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë:16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe vendimit për anulimin e konkursit me nr 308 të dt 01.02.2024   mardhënien kontraktuale dhe avansim në detyrë në ndërmarrje me nr.1338 të datë:20.06.2023, NPL”Gjelbër” Shpall këtë:",
    titulliPozites: "Praktikant - Zyrtar/e në Njësinë e Burimeve Njerëzore",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "20 orë në javë / gjysmë orari",
    paga: "",
    vendi: "Prishtinë",
    diploma: "Duke vijuar studimet ose përfunduar në Fakultetin Juridik ose Ekonomik",
    patentShofer: "",
    pervojaPunes: "",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "-	përgatit të dhëna për llogaritjen e pagave në bazë të dokumentacionit përkatës: aktvendimet, vendimet, listat e të punësuarve, pushimet mjekësore etj sipas udhëzimeve dhe në koordinim të ngushtë me Udhëheqësin e BNJ",
    detyra2: "-	siguron që dokumentet e stafit të rekrutuar të kompletohen, të plotësohen dhe të përpunohen në kohën e duhur",
    detyra3: "-	qëndron i përditësuar me ndryshimet në legjislacionin e punës dhe	kryen, analizon dhe interpreton të dhënat për dosjet e personelit",
    detyra4: "-	ndihmon departamentin në përgatitjen e programit të trajnimit për stafin e ri të departamenteve",
    detyra5: "-	ndihmon në koordinimin e planifikimit vjetor të pushimeve të punonjësve, mungesat nga puna të paguara dhe të papaguara,",
    detyra6: "-	mban të dhëna për pjesëmarrjen e stafit të Ndërmarrjes në programet e trajnimit dhe merr një vlerësim të efektivitetit të trajnimit -	administron praktikat dhe vendosjen e studentëve për praktika dhe vullnetar,",
    detyra7: "-	koordinon lëshimin e çertifikatave të punës lidhur me punonjësit e Ndërmarrjes dhe i përcjell kërkesat përkatëse ligjore, si kodi I punës dhe siguron që këto të respektohen",
    detyra8: "-	informohet për ndryshimet në legjislacionin e punës dhe udhëzimet përkatëse -	përpunon raporte dhe statistika të departamenteve -	merr pjesë statistikave dhe departamenteve -	merr pjesë në hartimin e planit të biznesit të Ndërmarrjes në fushë e BNJ. -	kryen punë të tjera të caktuara nga Udhëheqësi i Njësisë, sipas fushës së kompetencës dhe pozitës -	i përgjigjet Udhëheqësit të Burimeve Njerëzore dhe Drejtorit të Administratës.",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, formularin zyrtar mund ta marrin në Arkivën e Ndërmarrjes NPL”Gjelbër” SH.A  apo ta shkarkojnë nga web faqja e internetit në adresën www.gjelber.com. Dokumentacionin mund ta paraqesin në kopje fizike në Divizionin e Burimeve Njerëzore në adresën, rr. Tahir Zajmi, pn në Prishtinë, apo përmes Postës së Kosovës. Kandidatët të cilët konkurojnë duhet të shënojnë saktë pozitën në të cilën aplikojnë, adresën, numrin e telefonit kontaktues si dhe email adresën. Aplikacionet dhe dokumentacioni i pakompletuar sipas kërkesave të përcaktuara në këtë shpallje si dhe ato që arrijnë pas skadimit të afatit të konkursit, nuk do të shqyrtohen. Shpallja është e hapur që nga data 14.02.2024 – 29.02.2024.",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
  },
  {
    id:7,
    konkurs: "Konkurs",
    title: "Praktikant - (Arkivist)",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#FFF6E7',
    buttonColor: "#003249",
    textColor: "#003249",
    imageUrl: image2,
    publicationDate: '14.02.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë:16.06.2023 si dhe Rregullores për procedurat e rekrutimit, si dhe vendimit për anulimin e konkursit me nr 308 të dt 01.02.2024   mardhënien kontraktuale dhe avansim në detyrë në ndërmarrje me nr.1338 të datë:20.06.2023, NPL”Gjelbër” Shpall këtë:",
    titulliPozites: "Praktikant - (Arkivist)",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "40 orë në javë",
    paga: "",
    vendi: "Prishtinë",
    diploma: "Diplomë universitare",
    patentShofer: "",
    pervojaPunes: "puna provuese 6 muaj (me mundësi vazhdimi)",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "-	pranon kërkesat e shkresat të dorëzuara  nga palët jashtë dhe brenda ndërmarrjes dhe i shpërndan ato sipas kërkesës dhe nevojës në shërbimet përkatëse të ndërmarrjes;",
    detyra2: "-	udhëheqë protokollin e arkivit si dhe librat ndihmëse të parapara me ligjin e arkivit;",
    detyra3: "-	bënë pranimin e dokumentacionit arkivor, seleksionimin e tij dhe ruajtjen në bazë të ligjit mbi ruajtjen e materialit arkivor në ndërmarrje;",
    detyra4: "- mbanë vulat e ndërmarrjes të parapara me statut dhe vepron me to në kuadër të detyrave të punës;",
    detyra5: "-	mbanë librat interne dhe eksterne të ndërmarrjes duke e përfill ligjin mbi arkivin dhe ligjet tjera të parapara për përcjellën dhe ruajtjen e materialit arkivor;",
    detyra6: "-	kryen edhe punët tjera sipas kërkesës të Drejtorit të Administratës dhe Sekretarit -	kryen edhe punë tjera me kompjuter sipas nevojës së shërbimit;",
    detyra7: "-	për punën e tij/saj i përgjigjet Arkivistit/es dhe Drejtorit të Administratës.",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, formularin zyrtar mund ta marrin në Arkivën e Ndërmarrjes NPL”Gjelbër” SH.A  apo ta shkarkojnë nga web faqja e internetit në adresën www.gjelber.com. Dokumentacionin mund ta paraqesin në kopje fizike në Divizionin e Burimeve Njerëzore në adresën, rr. Tahir Zajmi, pn në Prishtinë, apo përmes Postës së Kosovës. Kandidatët të cilët konkurojnë duhet të shënojnë saktë pozitën në të cilën aplikojnë, adresën, numrin e telefonit kontaktues si dhe email adresën. Aplikacionet dhe dokumentacioni i pakompletuar sipas kërkesave të përcaktuara në këtë shpallje si dhe ato që arrijnë pas skadimit të afatit të konkursit, nuk do të shqyrtohen. Shpallja është e hapur që nga data 14.02.2024 – 29.02.2024.",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
  }, 
  {
    id:6,
    konkurs: "Konkurs",
    title: "Veterinar - Udhëheqës i Qendrës së Qenëve Endacak",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    imageUrl: image2,
    publicationDate: '29.01.2024',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë:16.06.2023 si dhe Rregullores për procedurat e rekrutimit, mardhënien kontraktuale dhe avansim në detyrë në ndërmarrje me nr.1338 të datë:20.06.2023, NPL”Gjelbër” Shpall këtë:",
    titulliPozites: "Veterinar - Udhëheqës i Qendrës së Qenëve Endacak",
    kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
    orari: "I plotë",
    paga: "Niveli i pagës: 4",
    vendi: "Prishtinë",
    diploma: "diplomë universitare - Fakulteti i Veterinarisë",
    pervojaPunes: "3 vite përvojë pune specifike  në fushë veprimtari të qendrës ",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    detyra1: "organizon të gjitha punët në qendrën për trajtimin e qenëve",
    detyra2: "kontrollon dhe vlerëson kafshët e kapura në terren",
    detyra3: "në bazë të diagnozës përpilon planin e operimit të kafshëve dhe ofron shërbime kirurgjikale për kafshët",
    detyra4: "ofron shërbime kujdesi dhe përkujdeset për terapinë post operative të kafshëve",
    detyra5: "sigurohet për shëndetin e kafshëve para se atë të lëshohen në qyte",
    detyra6: "harmonizon dhe programon bilancet materiale dhe shpenzuese medicinale për njësinë punuese dhe mbikëqyr punën e teknikëve të veterinarisë",
    detyra7: "kryen edhe punë të tjera me urdhëresë të Drejtorit të Operativës dhe Kryeshefit Ekzekutiv",
    detyra8: "-	për punën e tij i përgjigjet Drejtorit të Operativës dhe Kryeshefit Ekzekutiv",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Dëshmitë për kualifikim shkollor",
    aplikoni: "Kandidatët e interesuar, formularin zyrtar mund ta marrin në Arkivën e Ndërmarrjes NPL”Gjelbër” SH.A  apo ta shkarkojnë nga web faqja e internetit në adresën www.gjelber.com. Dokumentacionin mund ta paraqesin në kopje fizike në Divizionin e Burimeve Njerëzore në adresën, rr. Tahir Zajmi, pn në Prishtinë, apo përmes Postës së Kosovës. Kandidatët të cilët konkurojnë duhet të shënojnë saktë pozitën në të cilën aplikojnë, adresën, numrin e telefonit kontaktues si dhe email adresën. Shpallja është e hapur që nga data 10.01.2024 - 25.01.2024.",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
},
  {
    id: 3,
    konkurs: "Konkurs",
    title: "Zyrtar/e në Njësinë e Burimeve Njerëzore",
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    imageUrl: image2,
    publicationDate: '19.12.2023',
    headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë:16.06.2023 si dhe Rregullores për procedurat e rekrutimit, marrëdhënien kontraktuale dhe avancim në detyrë në ndërmarrje me nr. 1338 të datë: 20.06.2023, në bazë të vendimit më nr. 2659 të datës 18.10.2013, si dhe kërkesën me nr. 3476 dhe aprovimi nga Kryeshefe Ekzekutive. NPL ”Gjelbër” SH.A. me datë 19.12.2023 shpall këtë: ",
    titulliPozites: "Zyrtar/e në Njësinë e Burimeve Njerëzore",
    kontrata: "Gjashtë (6) muaj  më mundësi vazhdimi deri në përfundim të pushimit të lehonisë.",
    orari: "I plotë",
    paga: "Koeficienti 9",
    vendi: "Prishtinë",
    diploma: "Diplomë universitare - Fakulteti Juridik ose Ekonomik",
    pervojaPunes: "2 vite përvojë pune",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    aftesiOrganizative: "- përgatit të dhëna për llogaritjen e pagave në bazë të dokumentacionit përkatës: Aktvendimet, vendimet, listat e të punësuarve, pushimet mjekësore etj sipas udhëzimeve dhe në koordinim të ngushtë me Udhëheqësin e BNJ",
    shkathtesi:"-	siguron që dokumentet e stafit të rekrutuar të kompletohen, të plotësohen dhe të përpunohen në kohën e duhur",
    gjuhaAngleze: "Njohja e Gjuhës Angleze e preferuar.",
    detyra1: "-	kryen, analizon dhe interpreton të dhënat për dosjet e personelit",
    detyra2: "-	ndihmon departamentin në përgatitjen e programit të trajnimit për stafin e ri të departamenteve",
    detyra3: "-	ndihmon në koordinimin e planifikimit vjetor të pushimeve të punonjësve, mungesat nga puna të paguara dhe të papaguara",
    detyra4: "-	mban të dhëna për pjesëmarrjen e stafit të Ndërmarrjes në programet e trajnimit dhe merr një vlerësim të efektivitetit të trajnimit",
    detyra5: "- administron praktikat dhe vendosjen e studentëve për praktika dhe vullnetar dhe	koordinon lëshimin e çertifikatave të punës lidhur me punonjësit e Ndërmarrjes",
    detyra6: "-	përcjell kërkesat përkatëse ligjore, si kodi I punës dhe siguron që këto të respektohen",
    detyra7: "-	informohet për ndryshimet në legjislacionin e punës dhe udhëzimet përkatëse ",
    detyra8: "-	përpunon raporte dhe statistika të departamenteve dhe merr pjesë statistikave dhe departamenteve",
    detyra9: "-	merr pjesë në hartimin e planit të biznesit të Ndërmarrjes në fushë e BNJ.",
    detyra10: "- kryen punë të tjera të caktuara nga Udhëheqësi i Njësisë, sipas fushës së kompetencës dhe pozitës",
    detyra11: "-	i përgjigjet Udhëheqësit të Burimeve Njerëzore.",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme: "Diplomë universitare - Fakulteti Juridik ose Ekonomik",
    aplikoni:"Aplikacionet  duhet të dorëzohen të mbyllura në zarf, pranë zyrës së  NPL”Gjelbër” SH.A. rr Tahir Zajmi pn. Aplikacionet e dërguara me postë me rekomandë, të cilat mbajnë vulën postare mbi dërgesën e bërë ditën e fundit të afatit për aplikim, do të konsiderohen të vlefshme dhe do të merren në shqyrtim nëse arrijnë brenda dy (2) ditësh. Aplikacionet dhe dokumentacioni  i pakompletuar sipas kërkesave të përcakuara në këtë shpallje si dhe ato që arrijnë pas skadimit të afatit të konkursit, nuk do të shqyrtohen. Shpallja është e hapur që nga data 19.12.2023 - 03.01.2024.",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
  },
  {
    id: 1,
    konkurs: "Konkurs",
    title: 'Zyrtar Kryesor Financiar dhe i Thesarit',
    description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
    background: '#003249',
    buttonColor: "#6ADB84",
    textColor: "#FFF6E7",
    imageUrl: image2,
    publicationDate: '24.10.2023',
    headingTop: "Bazuar në vendimin e Bordit të Drejtorëve nr. 2655 të datës 18.10.2023 si dhe dispozitave të nenit 21 të Ligjit nr. 03/L-087 mbi Ndërmarrjet Publike, respektivisht dispozitave të nenit 13 të Ligjit nr. 04/L-111 për ndryshimin dhe plotësimin e ligjit nr. 03/L-087 për Ndërmarrjet Publike, nenit 5 pika 5.3 të Statutit të NPL “Gjelbër” SH.A, Bordi i Drejtorëve të NPL “Gjelbër” SH.A me datë 24.10.2023 shpall konkurs publik për këtë pozitë:",
    titulliPozites: "Zyrtar Kryesor Financiar dhe i Thesarit",
    kontrata: "3 vite me mundësi vazhdimi",
    orari: "I plotë",
    paga: "Sipas Rregullores së Brendshme të Ndërmarrjes",
    vendi: "Prishtinë",
    diploma: "Të posedojë diplomë universitare – Fakulteti Ekonomik",
    pervojaPunes: "Së paku 5 vite punë profesionale nga fusha e Financave dhe/ose Kontabilitetit.",
    kushtetKualifikimit: "Të plotësojë kushtet e kualifikimit sipas nenit 17.1 si dhe të plotësojë kriteret e pavarësisë sipas nenit 17.2 pikat (d),(f),(j),(k),(l) të Ligjit nr. 03/L-087 të Ndërmarrjeve Publike dhe Plotësim Ndryshimit të LNP nr. 04/L-111 neni 13;",
    njohjaLigjeve: "Njohja e mirë e ligjeve dhe rregulloreve në fuqi, me theks të veçantë në Ligjin për NP, Ligjin për Shoqëritë Tregtare;",
    aftesiOrganizative: "Të ketë aftësi organizative, drejtuese dhe aftësi të shkëlqyeshme të komunikimit;",
    shkathtesi:"Shkathtësi të dëshmuara në punë me grupe dhe organizim të ndërmarrjes apo të ngjashme;",
    shkathtesiKompjuterike: "Shkathtësi të mira në punën me kompjuter;",
    gjuhaAngleze: "Njohja e Gjuhës Angleze e preferuar.",
    detyra1: "Përpilon planin buxhetor dhe kërkesën për ndarje buxhetore të ndërmarrjes;",
    detyra2: "Përpilon planin e biznesit, raportet vjetore dhe tremujore brenda afateve të përcaktuara me Ligjin për Ndërmarrjet Publike;",
    detyra3: "Siguron që të gjitha faturat e rregullta të pranuara në ndërmarrje të regjistrohen dhe të ekzekutohen brenda afatit ligjor;",
    detyra4: "Është përgjegjës për zbatimin e të gjitha dispozitave financiare dhe standardeve të kontabilitetit, ligjet e aplikueshme në Kosovë, të cilat rregullojnë çështjet financiare dhe aktet normative të ndërmarrjes;",
    detyra5: "Organizon, udhëheq dhe kontrollon punën e departamentit financiar;",
    detyra6: "Mban evidencë dhe dokumentacion të saktë për investimet dhe pagesat e ndërmarrjes;",
    detyra7: "Kujdeset për pasurinë e ndërmarrjes, monitoron punën e komisioneve, kontrollon hyrje - daljet financiare në përgjithësi;",
    detyra8: "Përcjell gjendjen financiare në banka dhe bashkëpunon me institucionet bankare dhe organet tjera në interes të ndërmarrjes;",
    aplikacioniPlotesuar:"Aplikacionin e plotësuar",
    diplomaNevojshme:"Diplomën universitare nga Fakulteti Ekonomik;",
    aplikoni:"Aplikantët e interesuar kërkesën për aplikim me dokumentacion të rregullt, duhet ta paraqesin në arkivin e NPL ”Gjelbër’’Sh.A., Rr. Tahir Zajmi në Prishtinë objekti i Zjarrëfikësve, çdo ditë pune prej orës 07:00 – 15:00, në afat prej 30 ditësh nga data e publikimit të konkursit në mjetet e informimit. Data përfundimtare për dorëzimin e aplikacioneve është 24.11.2023. Aplikacioni mund të merret në zyrat e ndërmarrjes.",
    aplikacioniNenBetim: aplikacioni,
    aplikacioniPerPunesim: aplikacioniPunesimit,
  },
  {
      id: 2,
      konkurs: "Konkurs",
      title: "Koordinator/e për ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
      description: 'Ndërmarrja publike lokale “Gjelbër” SH.A shpall konkurs për...',
      background: '#006646',
      buttonColor: "#FFF6E7",
      textColor: "#FFF6E7",
      imageUrl: image2,
      publicationDate: '29.11.2023',
      headingTop: "Në bazë të nenit 8 të Ligjit të Punës nr.03/L-212, Rregullores mbi organizimin dhe sistematizimin e vendeve dhe detyrave të punës nr.1292 të datë:16.06.2023 si dhe Rregullores për procedurat e rekrutimit, marrëdhënien kontraktuale dhe avancim në detyrë në ndërmarrje me nr.1338 të datë:20.06.2023, NPL ”Gjelbër” SH.A. me datë 29.11.2023 shpall këtë konkurs: ",
      titulliPozites: "Koordinator/e për ngritjen dhe mirëmbajtjen e sipërfaqeve gjelbëruese",
      kontrata: "puna provuese 6 muaj (me mundësi vazhdimi)",
      orari: "I plotë",
      paga: "5",
      vendi: "Prishtinë",
      diploma: "Diplomë universitare- Fakulteti i Bujqësisë",
      pervojaPunes: "1 vit përvojë pune",
      detyra1:"organizon punët në ngritjen, mirëmbajtjen dhe rregullimin e të gjitha sipërfaqeve të gjelbëruara të qytetit të cilat iu janë dhënë në administrim kësaj ndërmarrje;",
      detyra2:"udhëheqë dhe disponon me evidencën e gjithmbarshme të punëtorëve të njësisë së tij punuese,",
      detyra3:"harmonizon punët në njësinë punuese me qëllim të shtimit të efikasitetit në punë",
      detyra4:"kontakton me kryepunëtorët dhe bënë mbikëqyrjen e punës së objekteve",
      detyra5:"përcjell vazhdimisht të gjitha punët e njësisë punuese,",
      detyra6:"kryen edhe punë të tjera me urdhëresë të eprorit,",
      detyra7:"për punën e tij i përgjigjet Drejtorit të Operativës",
      aplikacioniPlotesuar:"Formulari për aplikim i NPL”Gjelbër” SH.A",
      diplomaNevojshme:" dëshmit për kualifikim shkollor",
      aplikoni:"Aplikacionet duhet të dorëzohen të mbyllura në zarf, pranë zyrës së NPL”Gjelbër” SH.A. rr Tahir Zajmi pn. Aplikacionet e dërguara me postë me rekomandë, të cilat mbajnë vulën postare mbi dërgesën e bërë ditën e fundit të afatit për aplikim, do të konsiderohen të vlefshme dhe do të merren në shqyrtim nëse arrijnë Brenda dy (2) ditësh. Aplikacionet dhe dokumentacioni i pakompletuar sipas kërkesave të përcakuara në këtë shpallje si dhe ato që arrijnë pas skadimit të afatit të konkursit, nuk do të shqyrtohen. Shpallja është e hapur që nga data 29.11.2023 - 14.12.2023",
      aplikacioniNenBetim: aplikacioni,
      aplikacioniPerPunesim: aplikacioniPunesimit,
  }
  
  ]

    export {shpalljet}